/** @format */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Information.module.css";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Information = ({ data }) => {
    const { t } = useTranslation();
    const contactInfo = [
        {
            title: t("Email Address"),
            info: data.contactInfoEmail,
            hours: data.contactInfoAssistanceHours,
            icon: <FontAwesomeIcon icon={faEnvelope} />,
        },
        {
            title: t("Number"),
            info: data.contactInfoPhone,
            hours: data.contactInfoAssistanceHours,
            icon: <FontAwesomeIcon icon={faPhone} />,
        },
    ];

    return (
        <div>
            <div
                className={`2xl:container 2xl:mx-auto mx-5 lg:mx-10 lg:px-10 mb-[70px] bg-neutral-900 rounded-[35px] lg:rounded-[45px] text-white px-5 py-5 lg:py-[50px] sm:py-[100px] flex flex-col 2xl:px-[50px] gap-5 md:flex-row ff-inter justify-around`}
            >
                <div>
                    <span className={`text-2xl`}>
                        {data.contactInfoHead || "Contact Info"}
                    </span>
                    <h2
                        className={`my-3 w-full text-4xl lg:text-6xl font-bold sm:w-[15ch] leading-tight my-10`}
                    >
                        {data.contactInfoSubHead ||
                            "We are always happy to assist you"}
                    </h2>
                </div>
                <div className={`flex flex-col md:flex-row`}>
                    {contactInfo.map((info, index) => (
                        <div key={index} className={`mb-5 p-3`}>
                            <h3
                                className={`py-5 relative ${classes["info-title"]} text-2xl font-semibold`}
                            >
                                {info.title}
                            </h3>
                            <h3 className={`py-5 font-bold`}>
                                {info.icon} {info.info}
                            </h3>
                            <p className={`py-5 text-xl`}>
                                {t("Assistance hours")}:
                                <br />
                                {info.hours}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Information;
