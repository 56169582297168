/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import classes from "./OurJourney.module.css";
import { useTranslation } from "react-i18next";

const OurJourney = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className={`bg-orange-50 py-[50px] rounded-[50px]`}>
            <div
                className={`2xl:container px-5 lg:px-10 flex-col gap-[30x] flex-center text-center`}
            >
                <h1 className="text-2xl lg:text-5xl py-[20px] text-[#333333] font-bold ff-inter">
                    {data.journeyHead || `Our Journey`}
                </h1>
                <p className="text-base lg:text-2xl lg:text-3xl text-gray-600 py-4 mb-7">
                    {data.journeySubHead ||
                        `From the beginning, we expanded our work globally to become
                    one of the leading architectural firms, offering
                    distinguished designs and high-quality services. We always
                    look forward to more growth and innovation.`}
                </p>
                <div
                    className={`flex gap-7 flex-center ff-inter text-sm text-neutral-600 mb-10 w-full`}
                >
                    <Button
                        to="/work"
                        className={`${classes["see-our-projects-btn"]} `}
                    >
                        {t("See Our Projects")}
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                    <Button to="/contact-us">{t("Let’s talk")}</Button>
                </div>
            </div>
        </div>
    );
};

export default OurJourney;
