/** @format */

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./LangSwitcher.module.css";

const LangSwitcher = () => {
    const { i18n } = useTranslation();

    function changeLang(lang) {
        i18n.changeLanguage(lang);
    }

    const [isOpen, setIsOpen] = useState(false);

    function handleSwitcher() {
        setIsOpen((prev) => !prev);
    }

    const langs = i18n.options.resources;

    return (
        <div className={` relative inline-block text-left`}>
            <div>
                <button
                    type="button"
                    className={`inline-flex w-full justify-center items-center gap-x-1 px-3 py-[5px] text-sm text-gray-900 ${classes.button}`}
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={handleSwitcher}
                >
                    <p className="uppercase flex gap-2">
                        <img src={langs[i18n.language].flag || `https://flagsapi.com/${i18n.language.toLocaleUpperCase()}/flat/24.png`} alt={i18n.language} className="lg:hidden" />
                        {i18n.language}
                    </p>
                    <FontAwesomeIcon icon={faChevronDown} size="xs" />
                </button>
            </div>
            <div
                className={`langSw absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${isOpen ? "open" : "close"
                    }`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
            >
                <div className="py-1" role="none">
                    {Object.entries(langs).map(([key, value]) => (
                        <span
                            key={key}
                            className="flex gap-2 px-4 py-2 text-[17px] text-gray-700 cursor-pointer hover:bg-slate-200 transition-all"
                            role="menuitem"
                            id={`menu-item-${key}`}
                            onClick={() => {
                                changeLang(key);
                                handleSwitcher();
                            }}
                        >
                            <img src={value.flag || `https://flagsapi.com/${key.toLocaleUpperCase()}/flat/24.png`} alt={value.fullName} />
                            {key.toUpperCase()}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LangSwitcher;
