/** @format */

import { Link, NavLink, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import classes from "./Header.module.css";
import logo from "../../assets/images/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLinkedinIn,
    faWhatsapp,
    faFacebookF,
    faInstagram,
    faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import LangSwitcher from "./LangSwitcher";
import { useTranslation } from "react-i18next";
import homeHeroShape from "../../assets/images/homeHeroShape.png";
import { motion } from "framer-motion";

const socials = [
    { icon: <FontAwesomeIcon icon={faLinkedinIn} />, url: "#" },
    { icon: <FontAwesomeIcon icon={faWhatsapp} />, url: "#" },
    { icon: <FontAwesomeIcon icon={faPinterest} />, url: "#" },
    { icon: <FontAwesomeIcon icon={faFacebookF} />, url: "#" },
    { icon: <FontAwesomeIcon icon={faInstagram} />, url: "#" },
];

const Header = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const menu = t("menu", { returnObjects: true });

    const [topPosition, setTopPosition] = useState(
        window.innerWidth >= 1280 ? 22 : 5
    );
    const [isFirstRender, setIsFirstRender] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setTopPosition(window.innerWidth >= 1280 ? 22 : 5);
            setIsFirstRender(false);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className={`header ${classes.header}`}>
            <div className="flex justify-around items-center bg-gray-100 mb-4 py-3">
                <h3 className="text-sm">Welcome to GO STUDIO Go & Grow</h3>
                <div className="flex gap-2 text-sm items-center">
                    <span>Follow Us :</span>
                    <ul className="flex gap-3 border-r-2 border-gray-400 px-3 text-[15px]">
                        {socials.map((icon, index) => (
                            <li key={index}>
                                <a href={icon.url}>{icon.icon}</a>
                            </li>
                        ))}
                    </ul>
                    <LangSwitcher />
                </div>
            </div>
            <div className="flex justify-between items-start px-10 2xl:px-10">
                <Link to="/">
                    <img src={logo} alt="logo" className="lg:w-[85px]" />
                </Link>
                <div className={`${classes.mainMenu}`}>
                    <motion.div
                        style={{
                            position: "relative",
                            zIndex: 2,
                        }}
                        initial={{ top: "-120px" }}
                        animate={{ top: topPosition }}
                        transition={{
                            duration: 2,
                            type: "spring",
                            ease: [0.2, 0.1, 0.7],
                            bounce: 0,
                            delay: isFirstRender ? 1.5 : 0,
                        }}
                    >
                        <ul className={`${classes.ul} ff-inter`}>
                            {menu.map((ele, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={ele.path}
                                        className="text-sm xl:text-xl py-2 px-3"
                                    >
                                        {ele.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                    {location.pathname === "/" && (
                        <motion.div
                            style={{
                                zIndex: 1,
                                position: "absolute",
                                top: 0,
                                right: 0,
                                transformOrigin: "top right",
                            }}
                            className="lg:h-[70px] xl:h-auto"
                            initial={{ scaleY: 0.7, width: "70%" }}
                            animate={{ scaleY: 1, width: "100%" }}
                            transition={{
                                duration: 2,
                                type: "spring",
                                ease: [0.2, 0.1, 0.7],
                                bounce: 0,
                                delay: 0.5,
                            }}
                        >
                            <img
                                src={homeHeroShape}
                                alt="hero"
                                className={`${classes.ulBg} lg:h-[100%] xl:h-auto lg:!top-[14px] xl:!top-[29px]`}
                            />
                        </motion.div>
                    )}
                </div>
            </div>
        </div>
    );
};

export { Header };
