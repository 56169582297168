/** @format */

import logo from "../assets/images/loading.gif";
const PreLoader = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex-center z-[1000] bg-white">
            <img src={logo} alt={logo} />
        </div>
    );
};

export default PreLoader;
