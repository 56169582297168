/** @format */
import classes from "./Footer.module.css";
import image from "../../assets/images/logo.svg";
import { HashLink as Link } from "react-router-hash-link";
import { DataContext } from "../../store/MainContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { general, cultures, designs } = useContext(DataContext);
    const { t } = useTranslation();

    const footerLinks = [
        {
            header: t("Our Services"),
            links: designs.map((design) => {
                return {
                    label: design.name,
                    to: "/work#gallery",
                    state: { design: { id: design.id } },
                };
            }),
        },
        {
            header: t("Around the world"),
            links: cultures.map((culture) => {
                return {
                    label: culture.name,
                    to: "/work#gallery",
                    state: { culture: culture },
                };
            }),
        },
        { ...t("footer", { returnObjects: true }) },
        {
            header: t("Fullow Us"),
            links: [
                ...Object.keys(general?.social_network || {}).map((key) => {
                    if (!general?.social_network[key]) return false;
                    return {
                        label: key.toLocaleUpperCase(),
                        to: general?.social_network[key],
                    };
                }),
            ],
        },
    ];

    return (
        <>
            <div className={`${classes.footer}`}>
                <div>
                    <div className={` px-10 ${classes.container}`}>
                        <div className={classes.logo}>
                            <img src={image} alt="logo" />
                            <p>{general?.footer_text}</p>
                        </div>
                        <div className={classes.links}>
                            {footerLinks.map((section, index) => {
                                return (
                                    <div className={classes.col} key={index}>
                                        <h3>{section.header}</h3>
                                        <ul>
                                            {section.links.map(
                                                (link, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Link
                                                                {...link}
                                                                smooth
                                                            >
                                                                {link.label}
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className={`mx-10 ${classes.copyRight}`}>
                    <p>
                        {general?.copyright_text ||
                            `© 2024 by Go Studio. All rights reserved.`}
                    </p>
                </div>
            </div>
        </>
    );
};

export { Footer };
