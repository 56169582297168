/** @format */

import CharacterCard from "../globale/CharacterCard";
import CreateCarousel from "../globale/CreateCarousel";
import eorep from "../../assets/images/persons/eorep-2.png";
import african from "../../assets/images/persons/african-2.png";
import american from "../../assets/images/persons/american-2.png";
import arab from "../../assets/images/persons/arab-2.png";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";

const Exploring = () => {
    const { personas } = useContext(DataContext);

    return (
        <div className="">
            <div className={`2xl:container px-3 lg:px-10 items-end`}>
                <CreateCarousel fullWidth={true} overflow={true}>
                    <CharacterCard
                        className={`w-50 h-[45vw] lg:w-[28%] lg:h-[30vw] 1xl:w-[31%] 1xl:h-[30vw] max-h-[330px] lg:max-h-[523px] bg-neutral-200 rounded-[60px] aspect-square pt-3`}
                        CharacterImage={eorep}
                        arrowBottom="-7px"
                        linkData={{
                            to: "/work",
                            state: {
                                person: "eorep",
                                culture: personas[2].culture,
                            },
                        }}
                    />
                    <CharacterCard
                        className={`w-50 h-[45vw] lg:w-[23%] lg:h-[23vw] 1xl:w-[21%] 1xl:h-[19vw] max-h-[330px] bg-neutral-200 rounded-[60px] aspect-square pt-3`}
                        CharacterImage={african}
                        linkData={{
                            to: "/work",
                            state: {
                                person: "african",
                                culture: personas[1].culture,
                            },
                        }}
                    />
                    <CharacterCard
                        className={`w-50 h-[45vw] lg:w-[23%] lg:h-[23vw] 1xl:w-[21%] 1xl:h-[19vw] max-h-[330px] bg-neutral-200 rounded-[60px] aspect-square pt-3`}
                        CharacterImage={american}
                        linkData={{
                            to: "/work",
                            state: {
                                person: "american",
                                culture: personas[0].culture,
                            },
                        }}
                    />
                    <CharacterCard
                        className={`w-50 h-[45vw] lg:w-[23%] lg:h-[23vw] 1xl:w-[21%] 1xl:h-[30vw] max-h-[330px] lg:max-h-[485px] bg-neutral-200 rounded-[60px] aspect-square pt-3`}
                        CharacterImage={arab}
                        arrowBottom="-21px"
                        linkData={{
                            to: "/work",
                            state: {
                                person: "arab",
                                culture: personas[3].culture,
                            },
                        }}
                    />
                </CreateCarousel>
            </div>
        </div>
    );
};
export default Exploring;
