/** @format */

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Form, redirect } from "react-router-dom";

const ContactForm = () => {
    const { t } = useTranslation();
    return (
        <div className={` px-10 lg:px-10 mb-[50px]`}>
            <Form
                method="POST"
                className={`flex flex-wrap gap-4 justify-between text-2xl ff-inter`}
            >
                <div className={`flex flex-col my-5 w-full sm:w-[30%]`}>
                    <label htmlFor="name">{t("Your Name")}</label>
                    <input
                        id="name"
                        type="text"
                        required
                        name="name"
                        className={`border-b-slate-300 mt-2 border-solid border-b focus:outline-none`}
                    />
                </div>
                <div className={`flex flex-col my-5 sm:w-[30%] w-full`}>
                    <label htmlFor="email">{t("Email Address")}</label>
                    <input
                        id="email"
                        type="Email"
                        required
                        name="email"
                        className={`border-b-slate-300 mt-2 border-solid border-b focus:outline-none`}
                    />
                </div>
                <div className={`flex flex-col my-5 sm:w-[30%] w-full`}>
                    <label htmlFor="phone">
                        {t("Phone Number (optional)")}
                    </label>
                    <input
                        id="phone"
                        type="text"
                        name="phone"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        maxLength="11"
                        className="border-b-slate-300 mt-2 border-solid border-b focus:outline-none"
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                            );
                        }}
                    />
                </div>
                <div className={`w-full flex flex-col mt-10`}>
                    <label htmlFor="message">{t("Message")}</label>
                    <textarea
                        id="message"
                        required
                        name="content"
                        rows="3"
                        className={`border-b-slate-300 mt-2 border-solid border-b focus:outline-none resize-none`}
                    />
                </div>
                <button
                    type="submit"
                    className={`w-fit block h-[90px] px-10 rounded-[55px] border-2 border-gray-900 text-gray-900 text-[22px] font-semibold flex-center gap-3 hover:bg-gray-900 hover:text-white transition-all`}
                >
                    {t("Leave us a Message")}
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            </Form>
        </div>
    );
};
export default ContactForm;

async function action({ request }) {
    const formData = await request.formData();

    const fields = {};

    formData.forEach((value, key) => {
        fields[key] = value;
    });

    fetch("http://gostudioadmin.test/api/messages", {
        method: request.method,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Accept-Language": "en",
        },
        body: JSON.stringify(fields),
    })
        .then((resolve) => console.log(resolve))
        .catch((error) => console.log(error));

    return redirect("/");
}

export { action };
