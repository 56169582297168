/** @format */
import CreateCarousel from "./CreateCarousel";

const Filter = ({ designs, activeDesign, setActiveDesign, className = "" }) => {
    function handleClick(design) {
        setActiveDesign(design === activeDesign ? false : design);
    }

    return (
        <div className={`${className} flex flex-col gap-10`}>
            <CreateCarousel arrows={false} className=" my-5 ">
                {designs.map((design, index) => (
                    <div
                        className={`flex-center h-[40px] text-xs lg:text-lg cursor-pointer border-gray-900 rounded-[20px] p-5 text-gray-500 ff-inter text-nowrap ${
                            activeDesign.id == design.id && "active-cat"
                        }`}
                        key={index}
                        onClick={() => handleClick(design)}
                    >
                        {design.name}
                    </div>
                ))}
            </CreateCarousel>
        </div>
    );
};

export default Filter;
