/** @format */

import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { DataContext } from "../../store/MainContext";

const TextMoving = () => {
    const { home } = useContext(DataContext);
    const sentences = home.runnerTexts || [
        "Explore our designs through the global characters.",
        "Choose your character to see our regional work.",
        "Every region, a unique design. Click to explore.",
    ];
    const [activeSentence, setActiveSentence] = useState(0);

    useEffect(() => {
        const time = setInterval(() => {
            setActiveSentence((prev) =>
                prev < sentences.length - 1 ? prev + 1 : 0
            );
        }, 3000);
        return () => {
            clearInterval(time);
        };
    }, [sentences.length]);

    return (
        <div className="flex items-top justify-center mb-5 lg:mb-[-110px] lg:h-[120px] overflow-hidden">
            <motion.div
                className="text-3xl xl:text-4xl lg:w-[80%] 1xl:w-[40%] font-thin lg:pl-[23%] md2:pl-[18%] 1xl:pl-[125px] 2xl:pl-[150px] mx-5 lg:ml-30 max-w-[950px] 1xl:max-w-[750px]"
                key={activeSentence}
                initial={{ opacity: 1, y: "-100%" }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 1, y: 20 }}
                transition={{ duration: 1, bounce: 0 }}
            >
                {sentences[activeSentence]}
            </motion.div>
        </div>
    );
};

export default TextMoving;
