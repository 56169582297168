/** @format */

import { useContext } from "react";
import ContactForm from "../components/contact/ContactForm";
import Information from "../components/contact/Information";
import Socials from "../components/contact/Socials";
import { DataContext } from "../store/MainContext";

const ContactUs = () => {
    const { contactPage } = useContext(DataContext);

    return (
        <>
            <Socials data={contactPage} />
            <ContactForm />
            <Information data={contactPage} />
        </>
    );
};

export default ContactUs;

