/** @format */

import { useTranslation } from "react-i18next";

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <>
            <h1>{t('Not found page')}</h1>
        </>
    );
};

export { NotFound };
