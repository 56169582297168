/** @format */
import WhyUs from "../components/globale/WhyUs";
import BestProjects from "../components/main-home/BestProjects";
import ServicesSection from "../components/services/ServicesSection";
import ContactSection from "../components/services/ContactSection";
import HeroServices from "../components/services/HeroServices";
import { useContext } from "react";
import { DataContext } from "../store/MainContext";

const Services = () => {
    const { servicesPage } = useContext(DataContext);

    return (
        <>
            <HeroServices data={servicesPage} />
            <ServicesSection data={servicesPage} />
            <BestProjects data={servicesPage} />
            <WhyUs data={servicesPage} />
            <ContactSection data={servicesPage} />
        </>
    );
};

export default Services;

