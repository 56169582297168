/** @format */

import { Link } from "react-router-dom";

const Button = ({ children, className, ...props }) => {
    return (
        <Link
            {...props}
            className={`${className} w-fit block h-[45px] px-[15px] flex-center border border-solid rounded-[30px] border-gray-900`}
        >
            {children}
        </Link>
    );
};
export default Button;
