/** @format */
import { motion } from "framer-motion";
import Button from "../globale/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import HeroAboutImage from "../../assets/images/HeroAboutImage.png";
import heroAboutBubble from "../../assets/images/heroAboutBubble.svg";
import { useContext, useState } from "react";
import { DataContext } from "../../store/MainContext";
import VideoModel from "../globale/VideoModel";
import { useTranslation } from "react-i18next";

const HeroAboutSection = ({ data }) => {
    const { t } = useTranslation();
    const { isRTL } = useContext(DataContext);
    const [isOpen, setIsOpen] = useState(false);

    const handleVideo = (open) => setIsOpen((prev) => open);

    return (
        <div>
            <VideoModel
                isOpen={isOpen}
                videoSrc={data.heroVideo}
                setIsOpen={handleVideo}
            />
            <div
                className={` px-5 lg:px-10 flex lg:flex-row gap-8 lg:items-start justify-between flex-col ff-inter `}
            >
                <div className={`xl:w-1/3 lg:w-2/3 z-[1]`}>
                    <div>
                        <h1
                            className={`text-[24px] lg:text-[45px] xl:text-[55px] font-bold max-w-[20ch] py-2 md2:py-7 text-gray-900`}
                        >
                            {data.heroHead || "About Us"}
                        </h1>
                        <p className={`text-neutral-400 pb-4 text-[18px] `}>
                            {data.heroText ||
                                `Founded 4 years ago, we create unique architectural
                            projects that combine modernity and quality. We
                            strive to offer innovative architectural solutions
                            that meet our clients’ needs worldwide.`}
                        </p>
                    </div>
                    <div className="flex items-center justify-start gap-5 text-sm">
                        <div
                            className={`relative flex grow lg:grow-0 gap-3 items-center w-fit rounded-r-[20px] xl:mt-5`}
                        >
                            <Button
                                className={`flex  justify-center items-center`}
                                to="/contact-us"
                            >
                                {t("Contact US")}
                            </Button>

                            <span
                                className={`flex-center bg-white w-[37px] h-[37px] lg:w-[50px] lg:h-[50px] rounded-[50%] shadow-gray-300 shadow-lg cursor-pointer video`}
                                onClick={handleVideo}
                            >
                                <FontAwesomeIcon icon={faPlay} />
                            </span>
                            <span
                                className={`text-gray-400 duration-300 origin-left`}
                            >
                                {t("Watch Video")}
                            </span>
                        </div>
                    </div>
                </div>
                <motion.div className=" bg-no-repeat rounded-3xl lg:z-[-1] origin-right w-full xl:w-[53%] transition-all relative mt-[-50px] md:mt-[-110px]">
                    <motion.img
                        initial={{ scale: 0.6 }}
                        animate={{ scale: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring",
                            ease: [0.2, 0.1, 0.7],
                        }}
                        src={data.heroImage || HeroAboutImage}
                        alt="her about"
                        className="max-w-full"
                    />
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ delay: 1, type: "spring" }}
                        className={`absolute p-5 ${
                            isRTL
                                ? "lg:right-[-420px] md2:right-[-500px] 2xl:right-[-600px] "
                                : "lg:left-[-230px] md3:left-[-250px] md2:left-[-300px] md2:top-[450px] md1:left-[-330px] 1xl:left-[-402px] 2xl:left-[-502px]"
                        } origin-bottom-right xs4:bottom-[-15px] xs3:bottom-[-10px] bottom-[-20px] lg:bottom-[0px] xl:bottom-[15px] xs2:bottom-[-10px] bg-cover flex-center bg-orange-0100 w-[80%] sm:w-[75%]  xs1:w-[78%] xs2:text-wrap p-10 rounded-[20px] text-black text-[5px] xs1:text-[12px] xs2:text-[10px] xs2:w-[80%] sm:text-xs md:text-sm lg:text-[12px] md3:text-[14px] md2:text-base py-[50px] px-[45px] sm:py-[60px] lg:py-[50] sm:px-[75px]
                            xs3:text-[9px] xs4:w-[80%] xs4:text-[6px]
                            lg:w-[70%] md2:w-[75%] md1:w-[80%] 1xl:w-[85%] 2xl:w-[90%]`}
                        style={{
                            backgroundImage: `url(${heroAboutBubble})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 130%",
                            backgroundPositionY: "-20px",
                            lineHeight: "1.9",
                            overflow: "hidden", // Add overflow hidden to keep content inside
                            wordWrap: "break-word", // Allows words to break onto the next line
                            wordBreak: "break-word", // Prevents overflow for long words
                        }}
                    >
                        <br />
                        {data.heroBubble ||
                            `We’re here to provide global architectural solutions tailored to your needs. Let’s start your project today!`}
                        <br />
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default HeroAboutSection;
