/** @format */

import { useTranslation } from "react-i18next";

const ErrorPage = () => {
    const { t } = useTranslation();
    return <h1>{t('Error page')}</h1>;
};

export default ErrorPage;
