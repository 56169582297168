/** @format */
import BestBg from "../../assets/images/BestBg.png";
import classes from "./BestProjects.module.css";
import Kitchen from "../../assets/images/kitchen.png";
import Bedroom from "../../assets/images/Bedroom.png";
import Bathroom from "../../assets/images/Bathroom.png";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";

const BestProjects = ({ data }) => {
    const { categories } = useContext(DataContext);

    const images = categories || [
        { id: 1, name: "Kitchen", image: Kitchen },
        { id: 2, name: "Bedroom", image: Bedroom },
        { id: 3, name: "Bathroom", image: Bathroom },
    ];

    return (
        <div
            style={{ backgroundImage: `url(${BestBg})` }}
            className={`hidden sm:block ${classes.section} best-projects bg-no-repeat py-5 lg:py-[50px] mt-3 lg:my-10`}
        >
            <div className={` px-5 flex flex-col lg:flex-row gap-[20px]`}>
                <div
                    className={`md:mb-5 text-sm md:text-3xl font-[100] leading-snug sm:p-5 mr-[50px] flex flex-col justify-between`}
                >
                    <p className="ff-inter mb-5">
                        {data.categoriesHead || "Lorem ipsum dolor sit amet"}
                    </p>
                    <p className="hidden lg:block text-[7px] md:text-xs font-normal">
                        {data.categoriesSubHead || "More than 1000"}
                    </p>
                </div>
                <div
                    className={`flex gap-4 md:gap-7 pl-[10px] ${classes.images} justify-center flex-wrap`}
                >
                    {images.map((image, index) => (
                        <div
                            className="w-[28%] rounded-3xl overflow-hidden relative overlay"
                            key={index}
                        >
                            <img
                                src={image.image}
                                alt="projects"
                                className=" max-w-[100%] w-full h-full"
                            />
                            <span className="absolute top-[8%] left-[13%] text-sm font-semibold lg:text-3xl lg:font-[100] text-white">
                                {image.name}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BestProjects;
