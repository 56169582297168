/** @format */

import React, { useContext, useEffect, useState } from "react";
import classes from "./Carousel.module.css";
import CreateCarousel from "../globale/CreateCarousel";
import Filter from "../globale/Filter";
import { DataContext } from "../../store/MainContext";

const Carousel = ({ data }) => {
    const { projects, designs } = useContext(DataContext);

    const [activeDesign, setActiveDesign] = useState(false);
    const [activeItems, setActiveItems] = useState(projects);

    useEffect(() => {
        let filteredItems = projects;

        filteredItems = !activeDesign
            ? filteredItems
            : filteredItems.filter((project) => {
                  for (let design of project.designs)
                      if (design.id == activeDesign.id) return true;
                  return false;
              });

        setActiveItems(filteredItems);
    }, [activeDesign, projects]);

    return (
        <div className={` relative px-3 lg:px-10 mt-12`}>
            <div className="bg-gray-100 w-fit py-2 px-7 rounded-[16px] text-neutral-800 font-bold text-xl ff-inter mb-3">
                {data.bestProjectsHead || "Our best projects"}
            </div>
            <div className="lg:flex items-center justify-between w-full lg:flex-row flex-col block">
                <h2 className="sm:text-3xl font-semibold text-xl my-1 flex-grow xl:text-2xl lg:text-2xl lg:text-nowrap md2:text-2xl md:text-3xl">
                    {data.bestProjectsSub ||
                        "finest projects we’ve executed globally"}
                </h2>
                <Filter
                    designs={designs}
                    activeDesign={activeDesign}
                    setActiveDesign={setActiveDesign}
                />
            </div>
            <div className="px-1">
                <CreateCarousel arrows={true}>
                    {activeItems.map((project, index) => (
                        <div
                            className={classes.item}
                            key={index}
                            style={{ backgroundImage: `url(${project.image})` }}
                        ></div>
                    ))}
                </CreateCarousel>
            </div>
        </div>
    );
};

export default Carousel;
