/** @format */
import mobileHeroShape from "../../assets/images/homeHeroShape.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import heroImage from "../../assets/images/homeHero.jpg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import VideoModel from "../globale/VideoModel";
import { useState } from "react";
import { Link } from "react-router-dom";

const HeroSection = ({ data }) => {
    const { i18n, t } = useTranslation();

    const isRTL = i18n.options.resources[i18n.language]["dir"] === "rtl";

    const [isOpen, setIsOpen] = useState(false);

    const handleVideo = (open) => setIsOpen((prev) => open);

    return (
        <div>
            <VideoModel
                isOpen={isOpen}
                videoSrc={data.heroVideo}
                setIsOpen={handleVideo}
            />
            <div
                className={`px-5 lg:px-10 xl- flex lg:flex-row gap-8  justify-between flex-col pb-[35px] xl:mt-[15px]`}
            >
                <div className={`lg:w-1/3`}>
                    <div>
                        <h1
                            className={`text-[24px] lg:text-[35px] xl:text-[48px] font-bold max-w-[20ch] md:mt-5 md:text-[40px] py-[-20px] home-title text-neutral-800 leading-tight  xl:text-we`}
                        >
                            {data.heroHead || "Inspiring Future"}
                            <br />
                            <span
                                className={`text-[#E6932B] lg:text-neutral-800  max-w-[14ch] block`}
                            >
                                {data.heroHead2 || "Architectural Designs."}
                            </span>
                        </h1>
                        <p
                            className={`text-neutral-400 pb-5 mt-10 max-w-[31ch]`}
                        >
                            {data.heroText ||
                                "We deliver innovative design solutions for exterior,interior, and landscape."}
                        </p>
                    </div>
                    <div className="flex items-center justify-between relative z-[1]">
                        <div
                            className={`relative flex gap-1 items-center  w-fit bg-white rounded-r-[20px]  border-white border-solid text-xs lg:text-sm`}
                        >
                            <Link
                                to="/work"
                                className={`w-fit block px-[15px] flex-center border border-solid rounded-[30px] border-gray-900 hover:border-black hover:border-[3px] hover:mx-[-2px] transition-all h-[35px] py-1 px-[5px] lg:h-[40px] lg:px-[5px]`}
                            >
                                {t("Explore Our Work")}
                            </Link>
                            <div
                                className={`flex items-center gap-2 border border-solid rounded-[30px] border-gray-900 px-1 lg:px-3 py-1 lg:border-0 `}
                            >
                                <span
                                    className={`flex-center bg-white w-[25px] h-[25px] lg:w-[40px] lg:h-[40px] rounded-[50%] shadow-gray-300 shadow-lg cursor-pointer video`}
                                    onClick={handleVideo}
                                >
                                    <FontAwesomeIcon
                                        icon={faPlay}
                                        className="rtl:rotate-180"
                                    />
                                </span>
                                <span
                                    className={`text-gray-400 transition-transform duration-300 lg:mt-1 origin-left top-1/2 ltr:right-[-15px] rtl:left-[-150px]`}
                                >
                                    {t("Watch Video")}
                                </span>
                            </div>
                            <img
                                src={mobileHeroShape}
                                alt="hero"
                                className={`lg:hidden absolute top-[7px] z-[-1] max-w-[110%] lg:max-w-[120%] right-[-20px] lg:right-[-39px] rtl:right-[-2px] `}
                                style={{
                                    height: "190%",
                                    transform: isRTL ? "" : "rotateY(180deg)",
                                }}
                            />
                        </div>
                    </div>
                </div>
                <motion.div
                    className=" aspect-[5/3] bg-no-repeat rounded-[25px] lg:rounded-[48px] z-[0] origin-right w-full lg:w-[60%] relative mt-[-60px] lg:mt-[-70px]  xl:w-[57%]"
                    style={{
                        backgroundImage: `url(${data.heroImage || heroImage})`,
                        backgroundSize: "100% 100%",
                        transformOrigin: "right",
                    }}
                    initial={{ scaleX: 0.6 }}
                    animate={{ scaleX: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring",
                        ease: [0.2, 0.1, 0.7],
                        bounce: 0,
                        delay: 0.5,
                    }}
                ></motion.div>
            </div>
        </div>
    );
};

export default HeroSection;
