/** @format */

import "./App.css";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { Root } from "./pages/Root";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AboutUs from "./pages/AboutUs";
import Work from "./pages/Work";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import ErrorPage from "./pages/ErrorPage";
import { DataProvider } from "./store/MainContext";
import { action } from "./components/contact/ContactForm";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        id: "product",
        errorElement: <ErrorPage />,
        children: [
            { index: true, element: <Home /> },
            { path: "about", element: <AboutUs />, action: action },
            { path: "work", element: <Work /> },
            { path: "contact-us", element: <ContactUs />, action: action },
            { path: "services", element: <Services /> },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
]);

function App() {
    const { i18n } = useTranslation();

    const langs = i18n.options.resources;

    useEffect(() => {
        document.documentElement.setAttribute(
            "dir",
            langs[i18n.language].dir || "ltr"
        );
        document.documentElement.setAttribute(
            "lang",
            i18n.language
        );
        console.log(`Language changed to: ${i18n.language}`);
    }, [i18n.language]);

    return (
        <>
            <DataProvider>
                <RouterProvider router={router} />
            </DataProvider>
        </>
    );
}

export default App;

