/** @format */
import { useContext } from "react";
import classes from "./ServicesSection.module.css";
import SingleService from "./SingleService";
import { DataContext } from "../../store/MainContext";
import { useTranslation } from "react-i18next";

const ServicesSection = ({ data }) => {
    const { services } = useContext(DataContext);
    const { t } = useTranslation();

    return (
        <div>
            <div className="px-5 lg:px-10 mt-10">
                <div className="ff-inter">
                    <h2
                        className={`text-[20px] py-[9px] px-5 text-neutral-700 font-bold ff-inter bg-[#EDEDED] rounded-[15px] mt-[30px] lg:mt-0 xl:mt-[-30px] lg:w-fit`}
                    >
                        {t("Services List")}
                    </h2>
                    <h2 className="text-3xl text-black font-bold py-3">
                        {data.listHead ||
                            `Our services cover all stages of design.`}
                    </h2>
                    <p className="text-lg text-gray-400">
                        {data.listSubHead ||
                            ` We offer a comprehensive range of services tailored to
                        meet the needs of design and construction projects`}
                    </p>
                </div>
                <div className={`${classes.services}`}>
                    {services.map((service, index) => (
                        <SingleService key={index} service={service} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;
