/** @format */
// import AR from "./localization/ar.json";
import EN from "./localization/en.json";
import FR from "./localization/fr.json";
import DE from "./localization/de.json";
import ES from "./localization/es.json";

// import arFlag from "./assets/images/UnitedArabEmirates(AE).png";
import enFlag from "./assets/images/UnitedKingdom(GB).png";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: EN,
            fullName: "English",
            flag: enFlag,
        },
        // ar: {
        //     translation: AR,
        //     fullName: "Arabic",
        //     flag: arFlag,
        //     dir: "rtl",
        // },
        fr: {
            translation: FR,
            fullName: "French",
            flag: null,
        },
        de: {
            translation: DE,
            fullName: "German",
            flag: null,
        },
        es: {
            translation: ES,
            fullName: "Spanish",
            flag: null,
        },
    },
    fallbackLng: "en",
});

export default i18n;
