import React, { useState } from "react";
import leftArr from "../../assets/images/_Carousel arrow.svg";

const CarouselModal = ({ images, isOpen, onClose, initialIndex = 0 }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const nextSlide = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-transparent rounded-lg shadow-lg overflow-hidden w-11/12 md:w-3/4 lg:w-[60%] relative">
                <button
                    className="absolute top-4 right-4 text-white text-2xl font-semibold hover:text-gray-60 z-10 cursor-pointer
                    "
                    onClick={onClose}
                >
                    &times;
                </button>

                {/* Carousel Image Container */}
                <div className="relative flex items-center justify-center p-4 rounded-3xl">
                    <div
                        className="w-[1200px] h-[520px] overflow-hidden mx-2"
                        style={{ borderRadius: "5rem" }}
                    >
                        <img
                            src={images[currentIndex]}
                            alt={`Slide ${currentIndex + 1}`}
                            className="object-cover w-full h-full"
                        />

                        {/* Previous Button */}
                        <button
                            onClick={prevSlide}
                            className="absolute left-0 rotate-180 top-1/2 transform -translate-y-1/2"
                        >
                            <img
                                src={leftArr}
                                alt="Previous"
                                className="w-12 h-12 opacity-100 hover:opacity-90"
                            />
                        </button>

                        {/* Next Button */}
                        <button
                            onClick={nextSlide}
                            className="absolute right-0 top-1/2 transform -translate-y-1/2"
                        >
                            <img
                                src={leftArr}
                                alt="Next"
                                className="w-12 h-12 opacity-100 hover:opacity-90"
                            />
                        </button>
                    </div>
                </div>

                {/* Dot Indicators */}
                <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-2 bg-black p-2 rounded-full">
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`flex shrink-0 self-stretch my-auto w-3 h-3 rounded-lg ${
                                currentIndex === index
                                    ? "bg-white"
                                    : "bg-gray-500"
                            }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CarouselModal;
