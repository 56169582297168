/** @format */

import React, { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import jsonProjects from "../FallbackData/projects.json";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        projects: jsonProjects.projects,
        services: [],
        values: [],
        categories: [],
        cultures: [],
        designs: [],
        personas: [],
        isAr: false,
        home: [],
        aboutPage: [],
        servicesPage: [],
        workPage: [],
        contactPage: [],
        general: [],
    });
    const { i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Accept-Language": `${i18n.language}`,
            },
        };

        const fetchAllData = async () => {
            // const baseUrl = "http://gostudioadmin.test/api";
            const baseUrl = "https://data.goglobal-studio.com/api";
            try {
                const [
                    projects,
                    services,
                    values,
                    categories,
                    cultures,
                    designs,
                    personas,
                    home,
                    servicesPage,
                    workPage,
                    aboutPage,
                    contactPage,
                    general,
                ] = await Promise.all([
                    fetch(`${baseUrl}/projects`, options).then((res) =>
                        res.json()
                    ),
                    fetch(`${baseUrl}/services`, options).then((res) =>
                        res.json()
                    ),
                    fetch(`${baseUrl}/values`, options).then((res) =>
                        res.json()
                    ),
                    fetch(`${baseUrl}/categories`, options).then((res) =>
                        res.json()
                    ),
                    fetch(`${baseUrl}/cultures`).then((res) => res.json()),
                    fetch(`${baseUrl}/designs`).then((res) => res.json()),
                    fetch(`${baseUrl}/personas`).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=home`,
                        options
                    ).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=services`,
                        options
                    ).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=work`,
                        options
                    ).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=about`,
                        options
                    ).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=contact`,
                        options
                    ).then((res) => res.json()),
                    fetch(
                        `${baseUrl}/settings?group=general`,
                        options
                    ).then((res) => res.json()),
                ]);

                setData({
                    projects: projects.data,
                    services: services.data,
                    values: values.data,
                    categories: categories.data,
                    cultures: cultures.data,
                    designs: designs.data,
                    personas: personas.data,
                    isRTL: i18n.options.resources[i18n.language]["dir"] === "rtl",
                    home: home.data,
                    servicesPage: servicesPage.data,
                    aboutPage: aboutPage.data,
                    workPage: workPage.data,
                    contactPage: contactPage.data,
                    general: general.data,
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAllData();
    }, [i18n.language]);

    return (
        <DataContext.Provider
            value={{ ...data, loading, error, setError, setLoading }}
        >
            {children}
        </DataContext.Provider>
    );
};

