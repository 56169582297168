/** @format */
import { useContext } from "react";
import BestProjects from "../components/main-home/BestProjects";
import Carousel from "../components/main-home/Carousel";
import Experience from "../components/main-home/Experience";
import Exploring from "../components/main-home/Exploring";
import HeroSection from "../components/main-home/HeroSection";
import ServicesSection from "../components/main-home/ServicesSection";
import TextMoving from "../components/main-home/TextMoving";
import { DataContext } from "../store/MainContext";

const Home = () => {
    const { home } = useContext(DataContext);

    return (
        <>
            <HeroSection data={home} />
            <TextMoving data={home} />
            <Exploring data={home} />
            <Carousel data={home} />
            <BestProjects data={home} />
            <Experience data={home} />
            <ServicesSection data={home} />
        </>
    );
};
export { Home };

