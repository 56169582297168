/** @format */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab);

const Socials = ({ data }) => {
    const { general } = useContext(DataContext);

    const socials = general?.social_network;

    return (
        <div
            className={` px-5 lg:px-10 py-[50px] flex flex-col gap-6 sm:flex-row sm:justify-between sm:items-center`}
        >
            <h1
                className={`text-4xl lg:text-6xl pretty font-bold text-[#333333] ff-inter max-w-[755px] leading-snug`}
            >
                {data.formHead ||
                    "Get in touch with us. We're here to assist you."}
            </h1>
            <div
                className={`socials flex flex-row justify-start items-center gap-4 sm:flex-col`}
            >
                {Object.keys(socials || {}).map((social, index) => {
                    if (!socials[social]) return;
                    return (
                        <a
                            href={socials[social]}
                            key={index}
                            className={`flex-center w-[50px] h-[50px] rounded-[50%] border border-solid border-gray-500 text-[30px]`}
                            target="_blank"
                        >
                            <FontAwesomeIcon
                                className="w-[16px] h-[16px]"
                                icon={["fab", social]}
                            />
                        </a>
                    );
                })}
            </div>
        </div>
    );
};
export default Socials;
