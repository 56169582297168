/** @format */

import { Outlet } from "react-router-dom";
import { Footer } from "../components/globale/Footer";
import { Header } from "../components/globale/Header";
import { useContext, useEffect, useState } from "react";
import MobileHeader from "../components/globale/MobileHeader";
import { AnimatePresence } from "framer-motion";
import { DataContext } from "../store/MainContext";
import PreLoader from "./PreLoader";
import { Helmet } from "react-helmet";
import ScrollToTop from "../ScrollToTop";

const Root = () => {
    const { loading, general } = useContext(DataContext);

    const [isMobile, setIsMobile] = useState(false);
    const [delayLoading, setDelayLoading] = useState(true);

    useEffect(() => {
        if (window.innerWidth < 1024) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        window.scrollTo(0, 0);
    }, []);

    window.addEventListener("resize", () => {
        if (window.innerWidth < 1024) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    });
    useEffect(() => {
        if (!loading) {
            const timer = setTimeout(() => {
                setDelayLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    if (loading || delayLoading) {
        return <PreLoader />;
    }
    if (!loading) {
        return (
            <>
                <Helmet>
                    <title>{general.seo_title}</title>

                    <meta
                        name="description"
                        content={general.seo_description}
                    />
                    <meta name="keywords" content={general.seo_keywords} />
                    <meta name="title" content={general.seo_title} />
                    <meta property="og:title" content={general.seo_title} />
                    <meta
                        property="og:description"
                        content={general.seo_description}
                    />

                    <link rel="canonical" href={window.location.href} />

                    <link rel="icon" href={general.site_favicon} />

                    <meta property="og:image" content={general.site_logo} />

                    {Object.keys(general.seo_metadata || {}).map((key) => {
                        return (
                            <meta
                                key={key}
                                name={key}
                                content={general.seo_metadata[key]}
                            />
                        );
                    })}
                </Helmet>
                {!isMobile && <Header />}
                <AnimatePresence>
                    {isMobile && <MobileHeader />}
                </AnimatePresence>
                <Outlet />
                <Footer />
                <ScrollToTop />
            </>
        );
    }
};

export { Root };
