/** @format */

import icon1 from "../../assets/images/icon1.svg";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";

const WhyUs = ({ data }) => {
    const { values } = useContext(DataContext);
    const resones = values || [
        {
            name: "Design Quality",
            description:
                "Delivering top-notch architectural designs focused on creativity and innovation.",
            image: icon1,
        },
        {
            name: "Time Commitment",
            description: "Ensuring projects are delivered on time, every time.",
            image: icon1,
        },
        {
            name: "Competitive Pricing",
            description: "Offering the best designs at the best prices.",
            image: icon1,
        },
    ];

    return (
        <div className="2xl:container px-10 py-[50px] ff-inter">
            <div className={` flex-col flex justify-between items-center`}>
                <h2
                    className={`md:text-5xl py-[20px] text-[#333333] font-bold ff-inter text-3xl`}
                >
                    {data.chooseUsHead || "Why Choose Us?"}
                </h2>
                <p
                    className={`text-lg text-gray-500 leading-snug py-4 mb-7 max-w-[650px] text-center`}
                >
                    {data.chooseUsSubHead ||
                        "We are committed to delivering exceptional services and exceptional projects"}
                </p>
            </div>
            <div className="flex lg:flex-row flex-col gap-4">
                {resones.map((res, index) => (
                    <div
                        className="flex flex-col items-center flex-1 bg-[#F5F5F5] rounded-[30px] py-3 px-5 text-center"
                        key={index}
                    >
                        <img
                            src={res.image}
                            alt="icon"
                            className={`w-[70px] h-[70px]`}
                        />
                        <h3 className={`text-2xl font-bold py-4`}>
                            {res.name}
                        </h3>
                        <p
                            className={`text-gray-400 text-[16px] leading-tight`}
                        >
                            {res.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhyUs;
