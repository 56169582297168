/** @format */
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const DropDownFilter = ({
    caltures,
    activeCalture,
    setActiveCalture,
    className = "",
}) => {
    const navigation = useNavigate();
    const [open, setOpen] = useState(false);
    function handleClick(cat) {
        setOpen(!open);
        setActiveCalture(cat ? cat : false);
    }

    return (
        <div className={`${className} relative`}>
            <div
                className="flex-center gap-2 bg-gray-900 w-[250px] h-[40px] lg:h-[50px] rounded-[25px] text-white font-medium cursor-pointer max-w-full text-sm lg:text-lg"
                onClick={() => setOpen(!open)}
            >
                {!activeCalture.id ? "All Work" : activeCalture.name}
                <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{
                        transition: "0.3s",
                        transform: open ? "rotate(-180deg)" : "rotate(0deg)",
                    }}
                />
            </div>
            <motion.ul
                className={`absolute p-1 bg-white rounded-[20px] w-full font-medium text-base overflow-hidden max-w-[250px]`}
                transition={{ duration: 0.3 }}
                style={{
                    transition: "all 0.5s",
                    opacity: open ? "1" : "0",
                    maxHeight: open ? "unset" : "0px",
                }}
            >
                <li
                    onClick={() => handleClick(false)}
                    className={`flex-center py-2 w-full rounded-[20px] hover:bg-gray-100 ${activeCalture || "active-cat"
                        }`}
                >
                    All Work
                </li>
                {caltures.map((calture) => (
                    <li
                        className={`flex-center py-2 w-full rounded-[20px] hover:bg-gray-100 ${activeCalture.id == calture.id && "active-cat"
                            }`}
                        key={calture.id}
                        onClick={() => handleClick(calture)}
                    >
                        {calture.name}
                    </li>
                ))}
            </motion.ul>
        </div>
    );
};

export default DropDownFilter;

