/** @format */

import { useContext } from "react";
import ContactSectionAbout from "../components/about/ContactSectionAbout";
import HeroAboutSection from "../components/about/HeroAboutSection";
import OurJourney from "../components/globale/OurJourney";
import WhyUs from "../components/globale/WhyUs";
import { DataContext } from "../store/MainContext";

/** @format */
const AboutUs = () => {
    const { aboutPage } = useContext(DataContext);

    return (
        <>
            <HeroAboutSection data={aboutPage} />
            <OurJourney data={aboutPage} />
            <WhyUs data={aboutPage} />
            <ContactSectionAbout data={aboutPage} />
        </>
    );
};

export default AboutUs;

