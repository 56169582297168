/** @format */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contactSectionImage from "../../assets/images/contactSection.png";
import Button from "../globale/Button";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import classes from "./ContactSection.module.css";
import { useContext } from "react";
import { DataContext } from "../../store/MainContext";
import { useTranslation } from "react-i18next";

const ContactSection = ({ data }) => {
    const { general } = useContext(DataContext);
    const { t } = useTranslation();

    return (
        <div className={`bg-[#171717] py-[50px]`}>
            <div
                className={`2xl:container px-10 flex flex-wrap gap-5 justify-between items-center`}
            >
                <div className={`md:w-[35%]`}>
                    <img
                        src={data.contactUsImage || contactSectionImage}
                        alt="contact us"
                        className="max-w-full"
                    />{" "}
                </div>
                <div className="md:w-[60%]">
                    <h2 className="text-white text-2xl lg:text-5xl font-bold py-2 lg:py-6 ff-inter">
                        {data.contactUsHead || "Contact us"}
                    </h2>
                    <p className="text-[#F3F3F3] text-sm lg:text-lg lg:text-3xl py-3 lg:!leading-10">
                        {data.contactUsText ||
                            `We’re here to provide the best solutions for your
                            projects. Our team is ready to listen to your needs and
                            offer specialized consultations to ensure your vision
                            becomes reality. Let’s take the first steps toward
                            bringing your project to life.`
                        }
                    </p>
                    <div className="flex gap-5 py-3 ff-inter text-sm">
                        <Button
                            to="/contact-us"
                            className={`${classes["send-message-btn"]}`}
                        >
                            {t("Send Message")}
                            <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                        <Button
                            to={`https://wa.me/${general.support_phone}?text=Hello%20there!%20I'm%20interested%20in%20your%20services`}
                            className={`border-white text-white transition-all hover:border-2 hover:px-[14px]`}
                        >
                            WhatsApp
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSection;

