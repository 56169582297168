/** @format */
import { Link } from "react-router-dom";
import { ReactComponent as RoteatedArrow } from "../../assets/images/roteated-arrow.svg";
import classes from "./CharacterCard.module.css";

const CharacterCard = ({
    className,
    section,
    CharacterImage,
    linkData = null,
    arrowBottom,
}) => {
    return (
        <div className={`${classes.card} ${className} overlay relative`}>
            <div className={classes["char-image"]}>
                <img
                    src={CharacterImage}
                    alt="CharacterImage"
                    className="characterImage"
                />
                {linkData && (
                    <Link
                        {...linkData}
                        className="absolute bottom-0 left-0 w-full h-full z-[2]"
                    ></Link>
                )}
            </div>
            <div
                className={`${classes.arrow} flex-center`}
                style={{ bottom: arrowBottom === "0px" ? "0px" : undefined }}
            >
                <RoteatedArrow />
            </div>
            <span className=" 2xl:!bottom-[-21px] 2xl:!bottom-[-7px]"></span>
        </div>
    );
};

export default CharacterCard;
