/** @format */
import { useContext } from "react";
import Gallery from "../components/work/Gallery";
import HeroWork from "../components/work/HeroWork";
import { DataContext } from "../store/MainContext";

const Work = () => {
    const { workPage } = useContext(DataContext);

    return (
        <>
            <HeroWork data={workPage} />
            <Gallery />
        </>
    );
};

export default Work;
