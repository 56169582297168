import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname, hash } = useLocation();

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        let scrollTo = [0, 0];

        if (hash) {
            const section = document.querySelector(hash);

            if (section) {
                scrollTo = [0, section.offsetTop];
            }
        }

        setTimeout(() => window.scrollTo(scrollTo[0], scrollTo[1], 'smooth'), 50);
    }, [pathname]);
}

export default ScrollToTop;
