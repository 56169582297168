/** @format */
import CharacterCard from "../globale/CharacterCard";
import CreateCarousel from "../globale/CreateCarousel";
import work from "../../assets/images/worldmap.svg";
import eorep from "../../assets/images/persons/eorep-2.png";
import african from "../../assets/images/persons/african-2.png";
import american from "../../assets/images/persons/american-2.png";
import arab from "../../assets/images/persons/arab-2.png";
import workeorep from "../../assets/images/persons/ch - euorp.svg";
import workafrican from "../../assets/images/persons/ch - african.svg";
import workamerican from "../../assets/images/persons/ch- american.svg";
import workarab from "../../assets/images/persons/ch- ar.svg";
import { motion, useAnimationControls } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import homeHeroShape from "../../assets/images/homeHeroShape.png";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../store/MainContext";

const mapCrods = {
    images: {
        american: workamerican,
        african: workafrican,
        eorep: workeorep,
        arab: workarab,
    },
    normal: {
        lefttop: "left-0 top-0 scale-[1]",
        american: {
            width: "15%",
            scale: "1",
            active: false,
            bottom: "39%",
            right: "64%",
        },
        african: {
            width: "15%",
            scale: "1",
            active: false,
            bottom: "26%",
            right: "48%",
        },
        eorep: {
            width: "15%",
            scale: "1",
            active: false,
            bottom: "43%",
            right: "36%",
        },
        arab: {
            width: "20%",
            scale: "1",
            active: false,
            bottom: "34%",
            right: "22%",
        },
    },
    american: {
        lefttop: "left-[30%] top-[50%] scale-[2.3]",
        american: {
            width: "20%",
            scale: "1.2",
            active: true,
            bottom: "31%",
            right: "62%",
        },
        african: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "13%",
            right: "32%",
        },
        eorep: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "38%",
            right: "22%",
        },
        arab: {
            width: "15%",
            scale: "1",
            active: false,
            bottom: "24%",
            right: "5%",
        },
    },
    african: {
        lefttop: "left-[10%] top-[20%] scale-[2]",
        american: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "30%",
            right: "86%",
        },
        african: {
            width: "23%",
            scale: "1.1",
            active: true,
            bottom: "8%",
            right: "40%",
        },
        eorep: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "37%",
            right: "30%",
        },
        arab: {
            width: "15%",
            scale: "1",
            active: false,
            bottom: "23%",
            right: "14%",
        },
    },
    eorep: {
        lefttop: "left-[10%] top-[15%] scale-[2]",
        american: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "30%",
            right: "85%",
        },
        african: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "8%",
            right: "42%",
        },
        eorep: {
            width: "23%",
            scale: "1.1",
            active: true,
            bottom: "35%",
            right: "21%",
        },
        arab: {
            width: "14%",
            scale: "1",
            active: false,
            bottom: "20%",
            right: "15%",
        },
    },
    arab: {
        lefttop: "left-[0%] top-[0%] scale-[1.8]",
        american: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "32.5%",
            right: "86%",
        },
        african: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "10%",
            right: "46%",
        },
        eorep: {
            width: "12%",
            scale: "1",
            active: false,
            bottom: "39.5%",
            right: "36.5%",
        },
        arab: {
            width: "29%",
            scale: "1.1",
            active: true,
            bottom: "21%",
            right: "5.5%",
        },
    },
};

const HeroWork = ({ data }) => {
    const { personas } = useContext(DataContext);
    const [activePerson, setActivePerson] = useState(null);
    const controls = useAnimationControls();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const locState = location.state;

        if (locState?.person) setActivePerson(locState.person);

        controls.start({
            scale: 1,
            transition: {
                duration: 0.3,
                bounce: 0,
            },
        });
    }, []);

    return (
        /** @format */

        <div className="pb-[50px]">
            <div className="px-5 lg:px-10 flex flex-col lg:flex-row justify-between gap-3 relative">
                {/* Left Side Text */}
                <div className="w-full lg:w-[36%] relative sm-md:mt-1 mt-10 ff-inter overflow-hidden">
                    <h2 className="text-[24px] lg:text-[35px] xl:text-[48px] font-bold max-w-[17ch] py-2 md2:py-3 text-neutral-800 leading-[1.2]">
                        {data.heroHead || "Explore Our Works Across the Globe"}
                    </h2>
                    <p className="text-sm md:text-base lg:text-lg xl:text-xl text-[#999999] py-3  leading-relaxed sm-md:mt-1 mt-10">
                        {data.heroText ||
                            `We deliver diverse architectural projects, from exterior and interior designs to landscape creations. Select your region to explore.`}
                    </p>
                    <CreateCarousel
                        arrows={true}
                        overflow={true}
                        fullWidth={true}
                        className="pb-7 hidden lg:flex mt-0 xl:mt-10"
                        arrowClasses="w-[24px] mx-[-10px]"
                    >
                        {/* Character Cards */}
                        {[
                            { image: american, class: "card1" },
                            { image: african, class: "card2" },
                            { image: eorep, class: "card3" },
                            { image: arab, class: "card4" },
                        ].map((character, index) => (
                            <CharacterCard
                                key={index}
                                className={`w-[110px] xl:w-[140px] h-[120px] xl:h-[150px] bg-neutral-300 rounded-[25px] aspect-square pt-1 ${character.class}`}
                                CharacterImage={character.image}
                                iconSize="2xs"
                                section={"work"}
                                arrowBottom="0px"
                            />
                        ))}
                    </CreateCarousel>
                </div>

                {/* Discover Text with Improved Responsiveness */}
                <h2 className="hidden lg:flex absolute bottom-0 left-0 z-10 text-sm md:text-base xl:text-[0.95rem] 3xl:text-lg px-5 lg:px-10 lg:text-[0.75rem]">
                    {data.heroTailEnd ||
                        `Discover our top designs worldwide. Explore unique architectural projects in your area through these characters.`}
                </h2>

                {/* Right Side World Map and Character Thumbnails */}
                <div className="w-full lg:w-[63%] lg:h-max mt-auto  bg-[#FED7AA66] rounded-[30px] overflow-hidden relative aspect-[1.7/1] flex flex-col justify-end">
                    <div
                        className={`absolute ${
                            mapCrods[activePerson || "normal"].lefttop
                        } right-0 bottom-0 transition-all duration-700 ease-in-out`}
                    >
                        <img
                            src={work}
                            alt="work hero"
                            className="w-full absolute bottom-0 left-1/2 transform -translate-x-1/2"
                        />
                    </div>
                    <div className="w-full h-full">
                        {["american", "african", "eorep", "arab"].map(
                            (person, index) => (
                                <motion.div
                                    key={person}
                                    className="absolute"
                                    initial={{ scale: 0.2 }}
                                    animate={controls}
                                    transition={{
                                        duration: 0.4,
                                        delay: 0.8,
                                        bounce: 0,
                                    }}
                                    style={{
                                        scale: mapCrods[
                                            activePerson || "normal"
                                        ][person].scale,
                                        width: mapCrods[
                                            activePerson || "normal"
                                        ][person].width,
                                        bottom: mapCrods[
                                            activePerson || "normal"
                                        ][person].bottom,
                                        right: mapCrods[
                                            activePerson || "normal"
                                        ][person].right,
                                        transition: "all 0.8s",
                                        zIndex: 4 - index,
                                    }}
                                    onTap={() => {
                                        navigate(location, {
                                            state: {
                                                culture:
                                                    personas[index].culture,
                                            },
                                        });
                                        setActivePerson((p) =>
                                            p === person ? null : person
                                        );
                                    }}
                                >
                                    <img
                                        src={mapCrods.images[person]}
                                        alt="work hero"
                                        className="w-full"
                                    />
                                </motion.div>
                            )
                        )}
                    </div>
                    <img
                        src={homeHeroShape}
                        alt="services hero"
                        className="absolute bottom-0 left-0 w-[60%] lg:h-[15%] rotate-180"
                    />
                </div>

                <h2 className="lg:hidden text-neutral-400 text-base">
                    {data.heroTailEnd ||
                        `Discover our top designs worldwide. Explore unique architectural projects in your area through these characters.`}
                </h2>
            </div>
        </div>
    );
};

export default HeroWork;
