/** @format */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contactSectionImage from "../../assets/images/contactSectionAbout.png";
import contactsectiontile from "../../assets/images/contactsectiontile.svg";
import circle1 from "../../assets/images/circle1.svg";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import classes from "./ContactSectionAbout.module.css";
import { Form } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactSectionAbout = ({ data }) => {
    const { t } = useTranslation();
    return (
        <div className="bg-[#171717] py-[15px] mt-[50px]">
            <div className="2xl:container p-5 flex flex-col lg:flex-row flex-wrap lg:gap-1 justify-between items-start">
                <div className="lg:w-[37%] ff-inter py-[20px] px-5 lg-px-0 lg:pr-7">
                    <span className="text-white text-xl">
                        {data.contactUsHead || "Contact us"}
                    </span>
                    <h2 className="md:text-4xl py-3 text-white font-bold text-[30px]">
                        {data.contactUsSubHead ||
                            "Ready to start your design journey?"}
                    </h2>
                    <p className="text-neutral-300 text-lg py-4">
                        {data.contactUsText ||
                            `Contact us today to discuss your ideas and needs.Our
                        team is here to provide personalized solutions and
                        expert advice. Let’s bring your vision to life with a
                        consultation tailored just for you.`}
                    </p>
                    <Form method="POST" className={` flex flex-col gap-5 `}>
                        <input
                            placeholder={t("Name")}
                            id="name"
                            type="text"
                            required
                            name="name"
                            className={`bg-white h-[50px] min-w-full rounded-[40px] placeholder:text-black outline-none  indent-5`}
                        />
                        <input
                            placeholder={t("Email Address")}
                            id="email"
                            type="Email"
                            required
                            name="email"
                            className={`bg-white  h-[50px] rounded-[40px] placeholder:text-black outline-none indent-5`}
                        />
                        <input
                            placeholder={t("Your Phone")}
                            id="phone"
                            type="phone"
                            required
                            name="phone"
                            className={`bg-white  h-[50px] rounded-[40px] placeholder:text-black outline-none indent-5`}
                        />

                        <textarea
                            placeholder={t("Your message")}
                            id="message"
                            required
                            name="content"
                            className={`bg-white h-[110px] rounded-[20px] placeholder:text-black outline-none resize-none indent-5 pt-3`}
                        />
                        <button
                            type="submit"
                            className={`${classes["send-message"]} `}
                        >
                            {t("Send Massage")}
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </Form>
                </div>
                <div className="w-full lg:w-[62%] order-first lg:order-last relative">
                    <img
                        src={data.contactUsImage || contactSectionImage}
                        alt="contact "
                        className="w-full max-w-full"
                    />
                    <img
                        src={contactsectiontile}
                        alt="contact "
                        className="absolute bottom-0 left-0 w-[55%]"
                    />
                    <p className="absolute bottom-[-8px] text-[9px] sm:bottom-1 sm:gap-3 sm:text-xs md:bottom-5 left-0 text-white md:text-xl xl:text-2xl 2xl:text-3xl flex gap-1">
                        <img src={circle1} />
                        {data.contactUsTile || "We think about every detail"}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactSectionAbout;
