/** @format */
import classes from "./MobileHeader.module.css";
import Logo from "../../assets/images/logo.svg";
import menuBtn from "../../assets/images/menuBtn.png";
import { AnimatePresence, motion } from "framer-motion";
import LangSwitcher from "./LangSwitcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import menuLogo from "../../assets/images/menuLogo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MobileHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    function handleMenu() {
        setIsMenuOpen((prev) => !prev);
    }
    const { t, i18n } = useTranslation();
    const menu = t("menu", { returnObjects: true });

    return (
        <div className="py-8">
            <motion.div
                className={`${classes["mobile-header"]} w-full`}
                initial={{ top: "-200px" }}
                animate={{ top: 0 }}
                transition={{
                    duration: 2,
                    type: "spring",
                }}
            >
                <div className="flex justify-between align-middle items-center py-2 px-5 lg:px-10">
                    <div className={`w-[32px] md:w-[40px] lg:w-[32px]`}>
                        <Link to="/">
                            <img
                                src={Logo}
                                alt="logo"
                                className="max-w-full w-[50px]"
                            />
                        </Link>
                    </div>
                    <div
                        className={`w-[50px] cursor-pointer flex justify-end`}
                        onClick={handleMenu}
                    >
                        <img
                            src={menuBtn}
                            alt="menu btn"
                            className="max-w-full w-[25px]"
                        />
                    </div>
                </div>
            </motion.div>

            {/* start mobile menu */}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        className={`bg-[#171717] ${classes["mobile-menu"]} w-full h-full `}
                        initial={{ opacity: 0, y: -30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{
                            duration: 1,
                            type: "spring",
                        }}
                        exit={{ opacity: 0, y: -30 }}
                    >
                        <div className="container">
                            <div
                                className={`flex justify-between items-center py-5`}
                            >
                                <LangSwitcher />
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className={`text-white text-4xl`}
                                    onClick={handleMenu}
                                />
                            </div>
                            <div className={`w-1/4 py-5`}>
                                <img src={menuLogo} alt="mobile menu logo" />
                            </div>
                            <ul>
                                {menu.map((ele) => (
                                    <li
                                        key={ele.path}
                                        className={`text-4xl text-white flex justify-between items-center py-3 border-b border-gray-100 border-opacity-35`}
                                    >
                                        <Link
                                            to={ele.path}
                                            className="block grow"
                                            onClick={handleMenu}
                                        >
                                            {ele.name}
                                        </Link>
                                        {i18n.language === "en" ? (
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faChevronLeft}
                                            />
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MobileHeader;
