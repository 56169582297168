/** @format */

import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideoModel = ({ isOpen, videoSrc, setIsOpen }) => {

    const handleClose = () => setIsOpen(false);

    return (
        <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-[50000000]"
            style={{ display: isOpen ? "flex" : "none" }}
            onClick={handleClose}
        >
            <div
                className="absolute top-10 right-10 cursor-pointer text-white text-4xl"
                onClick={handleClose}
            >
                <FontAwesomeIcon icon={faCircleXmark} />{" "}
            </div>

            {isOpen &&
                <video
                    controls
                    className="w-full lg:w-1/2  h-[50%] object-cover"
                    onClick={(e) => e.stopPropagation()}
                >
                    <source src={videoSrc} type="video/mp4" />
                </video>
            }
        </div>
    );
};

export default VideoModel;

