/** @format */

import { useState } from "react";
import { NavLink } from "react-router-dom";
import experImage from "../../assets/images/ExperImage.png";
import experBg from "../../assets/images/experBG.svg";
import classes from "./Experience.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";

const Experience = ({ data }) => {
    const { t, i18n } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);

    const isRTL = i18n.options.resources[i18n.language]["dir"] === "rtl";

    return (
        <div
            className={`2xl:container mx-3 lg:px-8 lg:mx-auto lg:w-[80%] ${classes.section}`}
        >
            <motion.div
                className={`${classes.container} container py-3 rounded-[40px] bg-neutral-100 relative`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <motion.div
                    initial={{ width: "45%" }}
                    animate={{ width: isHovered ? "25%" : "45%" }}
                    transition={{ duration: 2, delay: 0.1 }}
                    className="w-[45%] absolute z-0 top-0 right-0"
                >
                    <img src={experBg} alt="exper" className="w-full h-full" />
                </motion.div>
                <div
                    className={`${classes.info} relative z-1 flex-grow md:pl-10`}
                >
                    <p className=" md:text-3xl leading-normal">
                        {data.aboutHead ||
                            "With 4 years of experience, we offer creative and functional architectural solutions."}
                    </p>
                    <div className={classes.links2}>
                        <NavLink
                            to="/about"
                            className={`bg-[#171717] text-white  transition-all  flex gap-4`}
                        >
                            {t("Learn More AboutUs")}
                            {isRTL ? (
                                <FontAwesomeIcon icon={faArrowLeft} />
                            ) : (
                                <FontAwesomeIcon icon={faArrowRight} />
                            )}
                        </NavLink>
                        <NavLink
                            to="/work"
                            className={`hover:bg-black hover:text-white transition-all hover:scale-110`}
                        >
                            {t("View Our Work")}
                        </NavLink>
                    </div>
                </div>
                <div className={`${classes.image} relative z-1`}>
                    <img src={data.aboutImage || experImage} alt="work" />
                </div>
                <div className={`${classes.links} relative z-1`}>
                    <NavLink
                        to="/about"
                        className={`bg-[#171717] text-white  transition-all flex gap-4`}
                    >
                        {t("Learn More About Us")}
                        {isRTL ? (
                            <FontAwesomeIcon icon={faArrowLeft} />
                        ) : (
                            <FontAwesomeIcon icon={faArrowRight} />
                        )}
                    </NavLink>
                    <NavLink
                        to="/work"
                        className={`hover:bg-black hover:text-white transition-all hover:scale-110`}
                    >
                        {t("View Our Work")}
                    </NavLink>
                </div>
            </motion.div>
        </div>
    );
};

export default Experience;
