/** @format */
import classes from "./CreatCarousel.module.css";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import arrowBg from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";

const CreateCarousel = ({
    children,
    arrows,
    className,
    fullWidth = false,
    overflow = false,
    arrowClasses = "",
}) => {
    const [width, setWidth] = useState(0);
    const [position, setPosition] = useState(0);
    const { i18n } = useTranslation();
    const carousel = useRef();

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
        setPosition(0);
    }, [children]);

    const handleNext = () => {
        setPosition((prev) => {
            const newPos = prev - 200;
            return newPos < -width ? -width : newPos;
        });
    };

    const handlePrev = () => {
        setPosition((prev) => {
            const newPos = prev + 200;
            return newPos > 0 ? 0 : newPos;
        });
    };

    return (
        <motion.div
            className={`flex ${classes.carousel} ${className} ${
                overflow ? "overflow-visible-x" : "overflow-hidden"
            }`}
            ref={carousel}
            style={{
                direction: "ltr",
                minWidth: fullWidth ? "100%" : "auto",
            }}
        >
            <motion.div
                drag="x"
                dragConstraints={{ right: 0, left: -width }}
                animate={{ x: position }}
                transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 30,
                }}
                className={`${classes["inner-carousel"]}`}
                style={{
                    direction: i18n.language === "ar" ? "rtl" : "ltr",
                    width: fullWidth ? "100%" : "auto",
                }}
            >
                {children}
            </motion.div>

            {arrows && (
                <>
                    <button
                        className={`${classes.arrow} ${classes.leftArrow} ${arrowClasses} hidden lg:block`}
                        onClick={handlePrev}
                        // style={{
                        //     display: `${position === 0 ? "none" : ""}`,
                        // }}
                    >
                        <img
                            src={arrowBg}
                            alt="arrow"
                            style={{ transform: "scaleX(-1)" }}
                        />
                    </button>
                    <button
                        className={`${classes.arrow} ${classes.rightArrow} ${arrowClasses} hidden lg:block`}
                        onClick={handleNext}
                        // style={{
                        //     display: `${position === -width ? "none" : ""}`,
                        // }}
                    >
                        <img src={arrowBg} alt="arrow" />
                    </button>
                </>
            )}
        </motion.div>
    );
};

export default CreateCarousel;
